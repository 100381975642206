import { ApplicationConfig } from "@angular/core";
import { provideRouter } from "@angular/router";

import { routes } from "./app.routes";
import { provideClientHydration } from "@angular/platform-browser";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { TokenInterceptorService } from "@directives/token-interceptor.service";
import { provideCharts, withDefaultRegisterables } from "ng2-charts";




export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideClientHydration(), provideHttpClient(
    withInterceptorsFromDi(),
  ),
  provideCharts(withDefaultRegisterables())
  ,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true,
  }


  ]
};
