export const environment = {
  production: false,
  apiurl: process.env["apiurl"] || "DEF_A",
  billingUrl: process.env["billingUrl"] || "DEF_A",
  hostUrl: process.env["hostUrl"] || "DEF_A",
  flutterkey: process.env["flutterkey"] || "DEF_A",
  paystackKey: process.env["paystackKey"] || "DEF_A",
  billingUserName: process.env["billingUserName"] || "DEF_A",
  billingPassword: process.env["billingPassword"] || "DEF_A",
  env: process.env["env"] || "DEF_A",
  googleOAuth: process.env["googleOAuth"] || "DEF_A",
  SOCKET_ENDPOINT: process.env["SOCKET_ENDPOINT"] || "DEF_A",
  socketUrl: process.env["socketUrl"] || "DEF_A",
  socketUsername: process.env["socketUsername"] || "DEF_A",
  socketPassword: process.env["socketPassword"] || "DEF_A",
  companyUrl: process.env["companyUrl"] || "DEF_A",
  ihvnBar: process.env["ihvnBar"] || "DEF_A",
  siteKey:process.env["siteKey"] || "DEF_A",
  apiKey:process.env["apiKey"] || "DEF_A",
};
