import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  selector: "app-preloader",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./preloader.component.html",
  styleUrl: "./preloader.component.scss"
})
export class PreloaderComponent {

}
